import CardStatusGeneral from "./CardStatusGeneral";

// Some products are commented according to the
// 'Final Active Products' excel provided by Rizwan
const amexProjectsList = [
    {
        code: 'BC',
        primeProductName: 'AMEX CORPORATE SAR',
        productName: 'American Express Corporate Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس للشركات',
    },
    {
        code: 'BS',
        primeProductName: 'AMEX BUSINESS SAR',
        productName: 'American Express Business Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الائتمانية لأصحاب الأعمال',
    },
    {
        code: 'SB',
        primeProductName: 'SAIB CORP-SME SAR',
        productName: 'SAIB American Express Corporate Card',
        productNameArabic: 'بطاقة البنك السعودي للإستثمار أمريكان إكسبريس للشركات',
    },
    {
        code: 'SS',
        primeProductName: 'SAIB CORPORATE SAR',
        productName: 'SAIB American Express Corporate Card',
        productNameArabic: 'بطاقة البنك السعودي للإستثمار أمريكان إكسبريس للشركات',
    },

    {
        code: 'UC',
        primeProductName: 'AMEX CORPORATE USD',
        productName: 'American Express Corporate Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس للشركات',
    },

    {
        code: 'US',
        primeProductName: 'AMEX BUSINESS USD',
        productName: 'American Express Business Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الائتمانية لأصحاب الأعمال',
    },

    {
        code: 'MS',
        primeProductName: 'ARAMCO TRAVEL SAR',
        productName: 'American Express Corporate Travel Account',
        productNameArabic: 'حساب السفر للشركات من أمريكان إكسبريس',
    },
    // {
    //     code: 'MU',
    //     primeProductName: 'ARAMCO TRAVEL USD',
    //     productName: 'ARAMCO TRAVEL USD',
    //     productNameArabic: 'ARAMCO TRAVEL USD'},
    // {
    //     code: 'CB',
    //     primeProductName: 'BLUE COMPANION',
    //     productName: 'Blue from American Express',
    //     productNameArabic: 'الزرقاء من أمريكان إكسبريس'},
    // {
    //     code: 'RB',
    //     primeProductName: 'BLUE REVOLVE',
    //     productName: 'Blue from American Express',
    //     productNameArabic: 'الزرقاء من أمريكان إكسبريس'},
    {
        code: 'TS',
        primeProductName: 'CORPORATE TRAVEL SAR',
        productName: 'American Express Corporate Travel Account',
        productNameArabic: 'حساب السفر للشركات من أمريكان إكسبريس',
    },
    // {
    //     code: 'TU',
    //     primeProductName: 'CORPORATE TRAVEL USD',
    //     productName: 'CORPORATE TRAVEL USD',
    //     productNameArabic: 'CORPORATE TRAVEL USD'},
    // {
    //     code: 'EG',
    //     primeProductName: 'ETIHAD GOLD REVOLVE',
    //     productName: 'The American Express Etihad Gold Card',
    //     productNameArabic:
    //         'بطاقة ضيف الاتحاد الذهبية الائتمانية من أمريكان إكسبريس'},
    // {
    //     code: 'EP',
    //     primeProductName: 'ETIHAD PLATINUM REVO',
    //     productName: 'The American Express Etihad Platinum Card',
    //     productNameArabic:
    //         'بطاقة ضيف الاتحاد البلاتينية الائتمانية من أمريكان إكسبريس'},
    {
        code: 'CG',
        primeProductName: 'GOLD COMPANION',
        productName: 'The American Express Gold Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الذهبية الائتمانية',
    },
    {
        code: 'RG',
        primeProductName: 'GOLD REVOLVE',
        productName: 'The American Express Gold Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الذهبية الائتمانية',
    },
    // {
    //     code: 'AS',
    //     primeProductName: 'SAR ASALAH REVOLVE',
    //     productName: 'SAR ASALAH REVOLVE',
    //     productNameArabic: 'SAR ASALAH REVOLVE'},
    {
        code: 'SG',
        primeProductName: 'SAR GOLD CHARGE',
        productName: 'The American Express Gold Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الذهبية',
    },
    {
        code: 'SR',
        primeProductName: 'SAR GREEN CHARGE',
        productName: 'The American Express Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس',
    },
    {
        code: 'SP',
        primeProductName: 'SAR PLATINUM CHARGE',
        productName: 'The Platinum Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس البلاتينية',
    },
    {
        code: 'PS',
        primeProductName: 'SAR PLATINUM REVOLVE',
        productName: 'The American Express Platinum Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس البلاتينية الائتمانية',
    },
    {
        code: 'CE',
        primeProductName: 'USD CENTURION CHARGE',
        productName: 'The Centurion Card',
        productNameArabic: ' بطاقة سنتشوريون',
    },
    {
        code: 'UG',
        primeProductName: 'USD GOLD CHARGE',
        productName: 'The American Express Gold Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الذهبية',
    },
    {
        code: 'UR',
        primeProductName: 'USD GREEN CHARGE',
        productName: 'The American Express Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس',
    },
    {
        code: 'UP',
        primeProductName: 'USD PLATINUM CHARGE',
        productName: 'The Platinum Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس البلاتينية',
    },
    {
        code: 'PU',
        primeProductName: 'USD PLATINUM REVOLVE',
        productName: 'The American Express Platinum Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس البلاتينية الائتمانية',
    },
    {
        code: 'BE',
        primeProductName: 'BAE CORPORATE SAR',
        productName: 'American Express Corporate Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس للشركات',
    },
    {
        code: 'M1',
        primeProductName: 'BLUE REVOLVE MR',
        productName: 'The American Express Blue Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الزرقاء',
    },
    {
        code: 'M2',
        primeProductName: 'BLUE COMPANION MR',
        productName: 'The American Express Blue Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الزرقاء',
    },
    {
        code: 'RH',
        primeProductName: 'USD GREEN HYBRID',
        productName: 'The American Express Green Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الخضراء',
    },
    // {
    //     code: 'C1',
    //     primeProductName: 'BUSINESS CREDIT SAR',
    //     productName: 'Business Credit Card',
    //     productNameArabic: 'بطاقة ائتمان الأعمال'},
    {
        code: 'C2',
        primeProductName: 'BUSINESS CREDIT USD',
        productName: 'American Express Business Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الائتمانية لأصحاب الأعمال',
    },
    {
        code: 'PC',
        primeProductName: 'USD PLATINUM COMP',
        productName: 'The American Express Platinum Credit Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس البلاتينية الائتمانية',
    },
    // {
    //     code: 'A1',
    //     primeProductName:
    //         'The Saudi Aramco American Express Corporate Platinum Card',
    //     productName: 'The Saudi Aramco American Express Corporate Platinum Card',
    //     productNameArabic:
    //         'The Saudi Aramco American Express Corporate Platinum Card'},
    {
        code: 'A2',
        primeProductName: 'PLAT CORPORATE SAR',
        productName: 'American Express Corporate Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس البلاتينية للشركات',
    },
    // {
    //     code: 'A3',
    //     primeProductName: 'The Saudi Aramco American Express Corporate Gold Card',
    //     productName: 'The Saudi Aramco American Express Corporate Gold Card',
    //     productNameArabic:
    //         'The Saudi Aramco American Express Corporate Gold Card'},
    {
        code: 'A4',
        primeProductName: 'GOLD ARAMCO SAR',
        productName: 'American Express Gold Corporate Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس الذهبية للشركات',
    },
    // {
    //     code: 'P1',
    //     primeProductName:
    //         'The Saudi Aramco American Express Corporate Purchasing Card',
    //     productName:
    //         'The Saudi Aramco American Express Corporate Purchasing Card',
    //     productNameArabic:
    //         'The Saudi Aramco American Express Corporate Purchasing Card'},
    // {
    //     code: 'P2',
    //     primeProductName:
    //         'The Saudi Aramco American Express Corporate Purchasing Card',
    //     productName:
    //         'The Saudi Aramco American Express Corporate Purchasing Card',
    //     productNameArabic:
    //         'The Saudi Aramco American Express Corporate Purchasing Card'},
    {
        code: 'MG',
        primeProductName: 'SAB MAGENTA SAR',
        productName: 'SAB American Express Corporate Card',
        productNameArabic: 'بطاقة ساب أمريكان إكسبريس للشركات',
    },
    {
        code: 'SV',
        primeProductName: 'AL FURSAN GOLD USD',
        productName: 'Alfursan American Express Credit Card',
        productNameArabic: 'بطاقة الفرسان الائتمانية من أمريكان إكسبريس',
    },
    {
        code: 'GS',
        primeProductName: 'PRE FUNDED CARD SAR',
        productName: 'American Express Corporate Card',
        productNameArabic: 'بطاقة أمريكان إكسبريس للشركات',
    },
    {
        code: 'MB',
        primeProductName: 'MARRIOTT BONVOY USD',
        productName: 'The Marriott Bonvoy American Express Credit Card',
        productNameArabic: 'بطاقة Marriott Bonvoy أمريكان إكسبريس الائتمانية',
    },
    {
        code: 'BB',
        primeProductName: 'SAB-AMEX CTA SAR',
        productName: 'SAB American Express Corproate Travel Account',
        productNameArabic: 'حساب السفر للشركات من ساب أمريكان إكسبريس',
    },
    {
        code: 'SA',
        primeProductName: 'SAB BUSINESS CREDIT CARD',
        productName: 'SAB American Express Business Credit Card',
        productNameArabic: 'بطاقة ساب أمريكان إكسبريس الائتمانية لأصحاب الأعمال',
    },
    {
        code: 'VP',
        primeProductName: 'AMEX VPAY SAR',
        productName: 'American Express vPayment',
        productNameArabic: 'حساب vPayment من أمريكان إكسبريس',
    },
    {
        code: 'VS',
        primeProductName: 'SAB VPAYMENT',
        productName: 'SAB American Express vPayment',
        productNameArabic: 'حساب vPayment من ساب أمريكان إكسبريس',
    },
];



const getAccountProductType = (accountKeyValues) => 
{
    let productType = "";
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == 'IsCorporate')
        {
            if (keyValue.data == 'Y') 
            {
                productType = "Corporate";
            }
        }

        if (keyValue.key == 'AccountProductType')
        {
            productType = keyValue.data || '';
        }

    });

    if (productType == 'C')
    {
        return "Charge";
    }
    else if (productType == 'R')
    {
        return "Revolve";
    }
    else 
    {
        return productType;
    }
}
const getAccountProductCode = (accountKeyValues) => 
{
    let productCode = '';
    if (accountKeyValues != null) 
    {
        accountKeyValues.forEach(keyValue =>
        {

            {
                if (keyValue.key == 'AccountProduct')
                { productCode = keyValue.data || ''; }
            }
        });
        return productCode
    }
}

const divideByExponents = (amount, currencyCode) =>
{
    switch (currencyCode)
    {
        case 'noDivide':
            let num3 = amount;
            if (num3 % 1 == 0)
            {
                num3 = num3 + '.00'
            }
            return num3;
        case '048':
        case '368':
        case '400':
        case '788':
        case '414':
        case '434':
        case '512':
        case '990':
            let num = (amount ?? doubleAmount ?? 0) / 1000;
            if (num % 1 == 0)
            {
                num = num + '.00'
            }
            return num;
        default:
            let num2 = (amount ?? doubleAmount ?? 0) / 100;
            if (num2 % 1 == 0)
            {
                num2 = num2 + '.00'
            }
            return num2;
    }
}

const getAccountProductName = (productCode, lang) =>
{
    let productName = "";
    amexProjectsList.forEach(keyValue =>
    {
        if (keyValue.code == productCode) 
        {
            if (lang === 'ar')
            {
                productName = keyValue.productNameArabic || '';
            }
            else
            {
                productName = keyValue.productName || '';
            }
        }
    });
    return productName;
}

const getPrimeProductName = (productCode) =>
{
    let primeProductName = "";
    amexProjectsList.forEach(keyValue =>
    {
        if (keyValue.code == productCode) 
        {
            primeProductName = keyValue.PrimeProductName || '';

        }
    });

    return primeProductName;
}


const getAccountProductNameFromList = (accountKeyValues) =>
{
    let productName = "";
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == 'AccountProductName') 
        {
            productName = keyValue.data || '';
        }
    });
    return productName;
}

const getSadadNumber = (accountKeyValues) =>
{
    let sadadNumber = "";
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == 'SADADNUMBER') 
        {
            sadadNumber = keyValue.data || '';
        }
    });
    return sadadNumber;
}

const getImage = (productName) =>
{
    for (let index = 0; index < obj.length; index++)
    {
        const element = obj[index];
        if (productName == element.productName)
        {
            return element.code
        }
    }
}

const getCardActivationStatus = (accountKeyValues, cardStgeneral) =>
{
    let cardActivationFlag = "";
    let renewedCardFlag = "";
    let cardDeliveryFlag = "";
    let formerExpiryDate = null;
    let isActive = true;
    let isEnableForCall = false;
    let isSusByUser = false;

    if (accountKeyValues.length != 0)
    {
        accountKeyValues.forEach(keyValue =>
        {

            if (keyValue.key == 'CardActivationFlag') 
            {
                cardActivationFlag = keyValue.data ?? '';
            }
            if (keyValue.key == 'RenewedCardFlag')
            {
                renewedCardFlag = keyValue.data ?? '';
            }
            if (keyValue.key == 'CardDeliveryFlag')
            {
                cardDeliveryFlag = keyValue.data ?? '';
            }
            if (keyValue.key == 'FormerExpiryDate')
            {
                formerExpiryDate = keyValue.data;
            }
            if (cardActivationFlag == 'N') 
            {
                isActive = false; // Call
                isEnableForCall = true;
            }
            else if (cardActivationFlag == 'N' && renewedCardFlag == 'N' && cardDeliveryFlag == 'Y') 
            {
                isActive = false; // Call
                isEnableForCall = true;
            }
            else if (cardActivationFlag == 'Y' && renewedCardFlag == 'N' && cardDeliveryFlag == 'Y' && formerExpiryDate == null)
            {
                isActive = true; // No Call
                isEnableForCall = false;
            }
            else if (cardActivationFlag == 'Y' && renewedCardFlag == 'Y' && cardDeliveryFlag == 'N') 
            {
                isActive = true; // No Call
                isEnableForCall = false;
            }
            else if (cardActivationFlag == 'Y' && renewedCardFlag == 'N' && cardDeliveryFlag == 'Y' && formerExpiryDate != null)
            {
                isActive = false; // Call
                isEnableForCall = true;
            }
            if (cardActivationFlag == 'N')
            {
                isActive = false;
            }
            if (cardStgeneral == 'S04')
            {
                isActive = false; // Temporary suspend
                isSusByUser = true;
            }

        });

    }
    return { isActive, isEnableForCall, isSusByUser };
}
const getPrimaryCard = (accountKeyValues, productType, productCode, productName, accountCreditRiskLimit) =>
{
    let isPrimaryCard = false;

    accountKeyValues.forEach(isPrimary =>
    {
        if ((productType != null && productType != '') && (productCode != null && productCode != '') && (productName != null && productName != '') && (accountCreditRiskLimit != null && accountCreditRiskLimit != ''))
        {

            if (isPrimary.key == 'PrimaryCardFlag' && isPrimary.data == 'Y')
            {
                isPrimaryCard = true
            }
        }
    });
    return isPrimaryCard
}

const getExpiry = (accountKeyValues) =>
{
    let expiry = null
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == "ExpiryDate")
        {
            expiry = keyValue.data
        }
    });
    return expiry
}

const getCardNo = (accountKeyValues) =>
{
    let card = null
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == "CardNumber")
        {
            card = keyValue.data
        }
    });
    return card.replaceAll('\*', '')
}

const getCardSerial = (accountKeyValues) =>
{
    let serialNo = null
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == "CardSerialNo")
        {
            serialNo = keyValue.data
        }
    });
    return serialNo
}

const getSerial = (accountKeyValues) =>
{
    let serialNo = null
    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.fieldName == "SERIALNO.")
        {
            serialNo = keyValue.fieldValue
        }
    });
    return serialNo
}

const getStrength = (pass) =>
{

    let score = 0
    // let length = 0
    // let specialChar = 0
    // let caseMix = 0
    // let numCharMix = 0

    const specialCharRegex = /[^A-Za-z0-9]/g
    const lowercaseRegex = /(.*[a-z].*)/g
    const uppercaseRegex = /(.*[A-Z].*)/g
    const numberRegex = /(.*[0-9].*)/g
    // const repeatCharRegex = /(\w)(\1+\1+\1+\1+)/g

    const hasSpecialChar = specialCharRegex.test(pass)
    const hasLowerCase = lowercaseRegex.test(pass)
    const hasUpperCase = uppercaseRegex.test(pass)
    const hasNumber = numberRegex.test(pass)
    // const hasRepeatChars = repeatCharRegex.test(pass)

    // if (pass.length > 1) {
    //   if ((hasLowerCase || hasUpperCase) && hasNumber) {
    //     numCharMix = 1
    //   }

    //   if (hasUpperCase && hasLowerCase) {
    //     caseMix = 1
    //   }

    //   if ((hasLowerCase || hasUpperCase || hasNumber) && hasSpecialChar) {
    //     specialChar = 1
    //   }

    //   if (pass.length > 8) {
    //     length = 1
    //   }

    //   if (pass.length > 12 && !hasRepeatChars) {
    //     length = 2
    //   }

    //   if (pass.length > 25 && !hasRepeatChars) {
    //     length = 3
    //   }
    let object = {
        uppercase: hasUpperCase,
        lowercase: hasLowerCase,
        number: hasNumber,
        character: hasSpecialChar
    }


    //   if (score > 4) {
    //     score = 4
    //   }
    // }

    return object
}

const getLastLogin = (clientKeyValues) =>
{
    let login = null
    clientKeyValues.forEach(keyValue =>
    {
        if (keyValue.fieldName == "ELMLASTCHECKDATE")
        {
            login = keyValue.fieldValue
        }
    });
    return login
}

const getAccountStGeneral = (accountKeyValues) =>
{
    let accountStGeneral = "";

    accountKeyValues.forEach(keyValue =>
    {
        if (keyValue.key == 'AccountStatus')
        {
            accountStGeneral += keyValue.data ?? '';
        }
        if (keyValue.key == 'AccountStatusReason')
        {
            accountStGeneral += keyValue.data ?? '';
        }
    });
    return accountStGeneral;
}

const getAvailableCredit = (accountProductCode, accountProductType, cardLimit) =>
{
    if (accountProductCode != 'CE' && accountProductType != 'Charge' && accountProductCode != 'RH')
    {
        return cardLimit.amt ?? '0';
    }
    else return 'No Pre-Set Limit'
}

const getCurrency = (code) =>
{
    // let arabic = !Constants.dataManager.isLanguageEn;
    let currency = "";
    switch (code)
    {
        case "682":
            currency = "§";
            // currency = arabic == true ? "ريال سعودي" : "SAR";
            break;
        case "840":
            currency = "USD";
            // currency = arabic == true ? "دولار" : "USD";
            break;
        case "978":
            currency = "EUR";
            // currency = arabic == true ? "EUR" : "EUR";
            break;
        case "124":
            currency = "CAD";
            // currency = arabic == true ? "CAD" : "CAD";
            break;
        case "144":
            currency = "LKR";
            // currency = arabic == true ? "LKR" : "LKR";
            break;
        case "036":
            currency = "AUD";
            // currency = arabic == true ? "AUD" : "AUD";
            break;
        case "784":
            currency = "AED";
            // currency = arabic == true ? "AED" : "AED";
            break;
        case "818":
            currency = "EGP";
            // currency = arabic == true ? "EGP" : "EGP";
            break;
        case "826":
            currency = "GBP";
            // currency = arabic == true ? "جنيه إسترليني" : "GBP";
            break;
        case "414":
            currency = "KWD";
            // currency = arabic == true ? "KWD" : "KWD";
            break;
        case "586":
            currency = "PKR";
            // currency = arabic == true ? "PKR" : "PKR";
            break;
        case "356":
            currency = "INR";
            // currency = arabic == true ? "INR" : "INR";
            break;
        case "949":
            currency = "TRY";
            // currency = arabic == true ? "TRY" : "TRY";
            break;
        default:
            return "§";
        // return arabic == true ? "ريال سعودي" : "SAR";
    }
    return currency;
}

const isSupplementaryCard = (cardStgeneral) =>
{
    let isSupplemtary = false;

    if (cardStgeneral == CardStatusGeneral.COMP ||
        cardStgeneral == CardStatusGeneral.FC ||
        CardStatusGeneral.isLost(cardStgeneral) ||
        cardStgeneral == CardStatusGeneral.PICK ||
        CardStatusGeneral.isStlc(cardStgeneral) ||
        cardStgeneral == CardStatusGeneral.CLSC ||
        cardStgeneral == CardStatusGeneral.CLSB ||
        CardStatusGeneral.isAtmc(cardStgeneral) ||
        cardStgeneral == CardStatusGeneral.DMGC ||
        cardStgeneral == CardStatusGeneral.DSTR ||
        cardStgeneral == CardStatusGeneral.CLSP ||
        CardStatusGeneral.isDmgd(cardStgeneral) ||
        cardStgeneral == CardStatusGeneral.PCOM)
    {
    } else
    {
        isSupplemtary = true;
    }
    return isSupplemtary;
}

const getDate = (dateTime) =>
{
    let day = moment(dateTime).day();
    let month = moment(dateTime).month()
    if (moment(dateTime).day() < 10)
    {
        day = '0' + moment(dateTime).day()
    }
    if (moment(dateTime).month() < 10)
    {
        month = '0' + moment(dateTime).month()
    }

    return `${day}-${month}-${moment(dateTime).year()}`
}

const canChangeLimit = (isPrimary, accountProductCode, accountProductType, accountStgeneral, isActive, name) =>
{
    {
        if (accountProductType == 'Revolve' ||
            accountProductCode == 'RH')
        {
            if (accountStgeneral == CardStatusGeneral.CLSC ||
                accountStgeneral == CardStatusGeneral.CLSP ||
                accountStgeneral == CardStatusGeneral.CLSB)
            {
                // Skip
            } else
            {
                if (isActive)
                {
                    return true
                }
            }
        }
    }
    return false;
}

const getMrAccountStatus = (accountLoyalty) =>
{
    let mrAccountStatus = '';
    if (accountLoyalty != null)
    {
        accountLoyalty.forEach(accountLoyaltyModel =>
        {
            if (accountLoyaltyModel.keyValues != null)
            {
                accountLoyaltyModel.keyValues.forEach(keyValue =>
                {
                    if (keyValue.key == 'AccountStatus')
                    {
                        if (keyValue.data != null)
                        {
                            mrAccountStatus = keyValue.data;
                        }
                    }
                });
            }
        });
    }
    return mrAccountStatus;
}

const getMrAccountNo = (accountLoyalty) =>
{
    let mrAccountNo = '';
    if (accountLoyalty.length > 0)
    {
        accountLoyalty.forEach(element =>
        {
            if (element.loyaltyAccountNbr != null)
            {
                mrAccountNo = element.loyaltyAccountNbr;
            }
        });
        // for (let loyality in accountLoyalty)
        // {
        //     if (loyality.loyaltyAccountNbr != null)
        //     {
        //         mrAccountNo = loyality.loyaltyAccountNbr;
        //     }
        // }
    }
    return mrAccountNo;
}

const getMembershipPointsVisibility = (selectedCard) =>
{
    let showMembershipPoints = false;

    if (selectedCard?.accountProductType == 'Corporate' &&
        selectedCard?.ProductCode == 'RB' &&
        selectedCard?.ProductCode == 'CB' &&
        selectedCard?.ProductCode == 'EP' &&
        selectedCard?.ProductCode == 'NMC' &&
        selectedCard?.ProductCode == 'EG')
    {
        showMembershipPoints = false;
    }
    else
    {
        if (selectedCard?.mRParticipationFlag == 'Y' || selectedCard?.mRParticipationFlag == 'A')
        {
            if (selectedCard?.mRAccountStatus == 'A' || selectedCard?.mRAccountStatus == 'N')
            {
                if (selectedCard?.isPrimaryCard == false)
                {
                    // if (selectedCard?.mRAccountStatus.trim() == 'I' ||
                    //     selectedCard?.mRAccountStatus.trim() == 'C' ||
                    //     selectedCard?.mRAccountStatus.trim() == '') {
                    //   if (selectedCard?.isSupplementaryCard(selectedCard) == false) {
                    //     showMembershipPoints = true;
                    //   }
                    // }
                }
                else
                {
                    showMembershipPoints = true;
                }
            }
        }
    }
    return showMembershipPoints;
}

const getMRParticipationFlag = (keyValueModels) =>
{
    let type = "";
    keyValueModels.forEach(keyValueModel =>
    {
        if (keyValueModel.key == 'MRENROLTYPE')
        {
            if (keyValueModel.data != null)
            {
                type = keyValueModel.data;
            }
        }
    });
    return type;
}

const getPhoneNumber = (address) =>
{
    if (address)
    {
        if (address.phone1)
        {
            if (address.phone1.phoneNumber)
            {
                return '********' + address.phone1.phoneNumber.substring(phoneNumber.length - 3)
            }
        }
    }
    return '';

};

const getCurrentBalance = (balance) =>
{
    // console.log('getCurrentBalance', balance)
    // let currentBalance = balance;
    if (balance <= 0)
    {
        return false;
    } else
    {
        return true
    }
    // return currentBalance;
}

export default {

    getAccountProductType,
    getAccountProductCode,
    getAccountProductName,
    getPrimeProductName,
    getCardActivationStatus,
    getPrimaryCard,
    getExpiry,
    getCardNo,
    getCardSerial,
    getStrength,
    getSadadNumber,
    getImage,
    divideByExponents,
    getLastLogin,
    getSerial,
    getAccountStGeneral,
    getAvailableCredit,
    getAccountProductNameFromList,
    getCurrency,
    isSupplementaryCard,
    canChangeLimit,
    getDate,
    getMrAccountStatus,
    getMembershipPointsVisibility,
    getMRParticipationFlag,
    getPhoneNumber,
    getCurrentBalance,
    getMrAccountNo
}
